<style>
.add-owner {
    float: right;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="stack.id === null">Register Stack</h5>
                <h5 v-if="stack.id !== null">Edit Stack</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="name" rules="">
                                        <b-form-input id="name" name="name" v-model="stack.name"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="volume" label="Volume">
                                    <validation-provider #default="{ errors }" name="volume" rules="">
                                        <b-form-input id="volume" name="volume" v-model="stack.volume"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.volume">{{
                                            serverErrors.volume[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <b-row>
                            <b-col cols="4">
                                <b-form-group labe-for="length" label="Length">
                                    <validation-provider #default="{ errors }" name="length" rules="">
                                        <b-form-input id="length" name="length" v-model="stack.length"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.length">{{
                                            serverErrors.length[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group labe-for="width" label="Width">
                                    <validation-provider #default="{ errors }" name="width" rules="">
                                        <b-form-input id="width" name="width" v-model="stack.width"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.width">{{
                                            serverErrors.width[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="4">
                                <b-form-group labe-for="Stack Grade" label="Stack Grade">
                                    <validation-provider #default="{ errors }" name="grade_id" rules="">
                                        <b-form-select size="md" v-model="stack.grade_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="grade in grades" :key="grade.id"
                                                :value="grade.id">
                                                {{ grade.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.grade_id" class="text-danger">{{
                                            serverErrors.grade_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>

                        </b-row>
                        <pre>
                            {{ stack }}
                        </pre>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
            </b-card-header>
            <b-card-body>
                <h6>Location: <strong> {{ warehouseView.location.name }}</strong></h6>
                <h6>Warehouse name: <strong> {{ warehouseView.name }} ({{ warehouseView.code }})</strong></h6>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Stack</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">


                <template #cell(status)="data">
                    <b-form-checkbox class="custom-control-success ml-4" name="check-button" switch
                        @change="handleCheckboxChange($event, data.item.id)" :checked="data.item.status">
                        <span class="switch-icon-left">
                            <feather-icon icon="CheckCircleIcon" />
                        </span>
                    </b-form-checkbox>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>


                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import warehousesStoreModule from '@/views/cromis/warehouse/warehouse/warehousesStoreModule'
import useStacksList from '@/views/cromis/warehouse/warehouse/useStacksList'


export default {
    props: ['id'],
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const grades = ref([])
        const children = ref([])
        const warehouseView = ref([])
        const totalShare = ref(100);
        const stack = ref({
            id: null,
            warehouse_id: null,
            grade_id: null,
            volume: null,
            name: null,
            width: null,
            length: null,
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-warehouse'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, warehousesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-warehouse/show', { id: props.id })
                .then(response => {
                    console.log(response)
                    warehouseView.value = response
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })

                await store.dispatch('cromis-warehouse/stackGrades')
                .then(response => {
                    grades.value = response.data.grades
                })
                .catch(error => {
                    console.log('got error', error)
                })


        })




        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useStacksList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            console.log(item)
            serverErrors.value = null


            stack.value = {
                id: item.id,
                warehouse_id: props.id,
                grade_id: item.grade_id,
                volume: item.volume,
                name: item.name,
                width: item.width,
                length: item.length,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            console.log(props)
            formSelections.value.map((form) => {
                form.model = null
            })

            stack.value = {
                id: null,
                warehouse_id: parseInt(props.id),
                grade_id: null,
                volume: null,
                name: null,
                width: null,
                length: null,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (warehouse.value.id === null || warehouse.value.id === 0)
                handleCreate()
            else
                handleUpdate(warehouse.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-warehouse/create', warehouse.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Market center has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-warehouse/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to warehouse center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete this Stack?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Stack is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            console.log(id)
            await store.dispatch('cromis-warehouse/remove', id)
                .then(response => {
                    refetch()
                    console.log(response)
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "status": event
            }

            await store.dispatch('cromis-warehouse/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Stack has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            stack,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: 'Select Owner' },
                { value: '1', text: 'Company' },
                { value: '2', text: 'Societies' },
                { value: '3', text: 'Third Part' }
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            handleCheckboxChange,
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            totalShare,
            companies,
            grades,
            warehouseView
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>